import Button from 'components/Button/Button';
import ReactSelect from 'components/FormElement/ReactSelect';
import { Option } from 'components/FormElement/types';
import BrightmontIcon from 'components/Icon/assets/BrightmontIcon';
import GreaterThanIcon from 'components/Icon/assets/GreaterThanIcon';
import LogoutIcon from 'components/Icon/assets/LogoutIcon';
import UserIcon2 from 'components/Icon/assets/UserIcon2';
import Image from 'components/Image';
import Modal from 'components/Modal';
import { PRIVATE_NAVIGATION } from 'constants/navigation.constant';
import { useAxiosGet } from 'hooks/useAxios';
import { events, LoaderStatusEnum } from 'interfaces/common.interface';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAuth } from 'reduxStore/slices/authSlice';
import {
  currentInstituteSelector,
  instituteSelector,
  setAvailabilityId,
  setCurrentInstitute,
  setInstitutes,
  setOptimizerId,
  setProcessId,
} from 'reduxStore/slices/instituteSlice';
import {
  getLoaderStatus,
  setLoaderState,
  setLoaderStatus,
  setLoaderStatusMessage,
  setPrimaryStep,
  setProgressStatus,
} from 'reduxStore/slices/loaderSlice';
import { clearToken } from 'reduxStore/slices/tokenSlice';
import { removeDuplicates } from 'utils';

export default function Header() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useSelector(getAuth);
  const institutes = useSelector(instituteSelector);
  const currentInstitute = useSelector(currentInstituteSelector);
  const loaderStatus = useSelector(getLoaderStatus);
  const navigate = useNavigate();
  const [callApi] = useAxiosGet();
  const dispatch = useDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [navigateTo, setNavigateTo] = useState('');

  useEffect(() => {
    navigate(navigateTo);
  }, [navigateTo]);

  const getOptions = async (
    search: string,
    _loadedOptions: unknown,
    pagination?: { page: number }
  ) => {
    const response = await callApi('/institute', {
      params: {
        page: pagination?.page,
        limit: 10,
        ...(search && { search }),
      },
    });

    const { data } = response;

    let options = data?.responseData?.map((item: { id: number; name: string }) => ({
      value: item.id,
      label: item.name,
    }));

    if (currentInstitute) {
      // Made changes because it give duplicated entry which is already selected please cross check it
      // options = removeDuplicates([currentInstitute, ...options]);
      options = removeDuplicates([...options]);
    }
    if (data?.currentPage === 1) {
      dispatch(setInstitutes(data.responseData));
    } else {
      dispatch(setInstitutes([...institutes, ...data.responseData]));
    }

    if (
      data?.currentPage === 1 &&
      !search &&
      !currentInstitute &&
      options.length > 0
    ) {
      dispatch(setCurrentInstitute(options[0]));
    }
    return {
      hasMore: data?.currentPage < data?.totalPages,
      options: options ?? [],
      additional: {
        page: !pagination ? 1 : pagination.page + 1,
      },
    };
  };

  const isActive = (path: string) => {
    const paths = Object.values(PRIVATE_NAVIGATION).map((nav) => nav.path);
    const currentIndex = paths.indexOf(window.location.pathname);
    const targetIndex = paths.indexOf(path);
    return targetIndex <= currentIndex;
  };

  const getProcessId = async () => {
    const { data } = await callApi(
      `/schedule-process-logs/last-run-data/${currentInstitute?.value}`
    );

    if (data) {
      dispatch(setLoaderStatusMessage(data?.socket ?? ''));

      if (data.optimizer_id) {
        dispatch(setOptimizerId(data?.optimizer_id));
      } else {
        dispatch(setOptimizerId(''));
        setNavigateTo(PRIVATE_NAVIGATION.scheduleGeneration.path);
      }

      if (data.availability_id) {
        dispatch(setAvailabilityId(data?.availability_id));
      } else {
        dispatch(setAvailabilityId(''));
        setNavigateTo(PRIVATE_NAVIGATION.scheduleGeneration.path);
      }

      if (data.process_id) {
        dispatch(setProcessId(data.process_id));

        dispatch(setLoaderState(false));
        if (data.step) {
          const event = events[data.step as keyof object] as {
            id: number;
            message: string;
          };
          dispatch(setLoaderStatusMessage(event.message));
          dispatch(setProgressStatus(event.id));
        }
        if (
          data.status === LoaderStatusEnum.COMPLETE ||
          data.status === LoaderStatusEnum.AVAILABILITY_PENDING ||
          data.status === LoaderStatusEnum.AVAILABILITY_SUCCESS
        ) {
          dispatch(setPrimaryStep(4));
        } else if (data.status === LoaderStatusEnum.SUCCESS) {
          dispatch(setPrimaryStep(3));
        } else if (data.status === LoaderStatusEnum.IN_PROGRESS) {
          dispatch(setPrimaryStep(2)); // preparing model in p
        } else if (data.status === LoaderStatusEnum.PENDING) {
          dispatch(setPrimaryStep(1));
        } else if (data.status === LoaderStatusEnum.CREATE) {
          dispatch(setPrimaryStep(0)); // pulling data
        }
        if (
          data.status === LoaderStatusEnum.COMPLETE ||
          data.status === LoaderStatusEnum.AVAILABILITY_PENDING ||
          data.status === LoaderStatusEnum.AVAILABILITY_SUCCESS
        ) {
          setNavigateTo(PRIVATE_NAVIGATION.calendar.path);
        } else if (
          data.status === LoaderStatusEnum.NOT_STARTED ||
          data.status === LoaderStatusEnum.FINISH ||
          data.status === LoaderStatusEnum.FAIL ||
          data.status === LoaderStatusEnum.INFEASIBLE ||
          data.status === LoaderStatusEnum.INTERNAL_SERVER_ERROR
        ) {
          setNavigateTo(PRIVATE_NAVIGATION.scheduleGeneration.path);
        } else {
          setNavigateTo(PRIVATE_NAVIGATION.dataValidation.path);
        }
      } else {
        dispatch(setProcessId(''));
        setNavigateTo(PRIVATE_NAVIGATION.scheduleGeneration.path);
      }
      dispatch(setLoaderStatus(data.status));
    }
  };

  useEffect(() => {
    if (currentInstitute?.value) {
      dispatch(setLoaderState(true));
      getProcessId();
    }
  }, [currentInstitute?.value]);

  return (
    <header className="py-[10px] bg-primaryLight-20 shadow-sm px-[20px]">
      <div className="h-[40px] flex justify-between w-full">
        <Modal modalEl={modalRef} setIsOpen={setIsOpen} />
        <nav className="flex gap-[20px] items-center text-grayText">
          <BrightmontIcon className="w-[113px] h-[40px]" />
          {/* {navItems.map((navItem, index) => ( */}
          <NavLink
            to={PRIVATE_NAVIGATION.scheduleGeneration.path}
            className={`
                  text-[16px]
                  ${
                    isActive(PRIVATE_NAVIGATION.scheduleGeneration.path)
                      ? 'text-primary font-600'
                      : 'font-light font-400 text-grayTextLight'
                  }
                `}
          >
            {PRIVATE_NAVIGATION.scheduleGeneration.title}
          </NavLink>
          <GreaterThanIcon />
          <NavLink
            to={
              loaderStatus === LoaderStatusEnum.FINISH ||
              loaderStatus === LoaderStatusEnum.NOT_STARTED ||
              loaderStatus === LoaderStatusEnum.FAIL ||
              loaderStatus === LoaderStatusEnum.INFEASIBLE
                ? ''
                : PRIVATE_NAVIGATION.dataValidation.path
            }
            className={`
                  text-[16px]
                  ${
                    isActive(PRIVATE_NAVIGATION.dataValidation.path)
                      ? 'text-primary font-600'
                      : 'font-light font-400 text-grayTextLight'
                  }
                      ${loaderStatus === LoaderStatusEnum.FINISH || loaderStatus === LoaderStatusEnum.NOT_STARTED || loaderStatus === LoaderStatusEnum.FAIL || loaderStatus === LoaderStatusEnum.INFEASIBLE ? 'cursor-not-allowed' : 'hover:text-primary hover:underline hover:font-600'}
                `}
          >
            {PRIVATE_NAVIGATION.dataValidation.title}
          </NavLink>
          <GreaterThanIcon />
          <NavLink
            to={
              loaderStatus === LoaderStatusEnum.COMPLETE ||
              loaderStatus === LoaderStatusEnum.AVAILABILITY_PENDING ||
              loaderStatus === LoaderStatusEnum.AVAILABILITY_SUCCESS
                ? PRIVATE_NAVIGATION.calendar.path
                : ''
            }
            className={`
                  text-[16px]
                  ${
                    isActive(PRIVATE_NAVIGATION.calendar.path)
                      ? 'text-primary font-600'
                      : 'font-light font-400 text-grayTextLight'
                  }
                  ${
                    loaderStatus !== LoaderStatusEnum.COMPLETE &&
                    loaderStatus !== LoaderStatusEnum.AVAILABILITY_PENDING &&
                    loaderStatus !== LoaderStatusEnum.AVAILABILITY_SUCCESS
                      ? 'cursor-not-allowed'
                      : ' hover:text-primary hover:underline hover:font-600'
                  }
                `}
          >
            {PRIVATE_NAVIGATION.calendar.title}
          </NavLink>
        </nav>
        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <ReactSelect
              className="bg-white text-grayText mr-2 rounded-lg text-lg"
              selectedValue={currentInstitute}
              onChange={(option) => {
                dispatch(setCurrentInstitute(option as Option));
              }}
              isPaginated
              loadOptions={getOptions}
              placeholder="Select an institute"
              isClearable={false}
            />
          </div>
          <div className="relative">
            <Button
              className="bg-white rounded-full overflow-hidden text-2xl font-semibold hover:ring-2 hover:ring-primary transition-all duration-200"
              onClickHandler={() => setIsOpen(!isOpen)}
            >
              <Image
                src="/images/profile.jpeg"
                alt="Profile"
                width={40}
                height={40}
              />
            </Button>
            {isOpen && (
              <div
                className="flex flex-col absolute top-full right-0 mt-2 bg-white text-black rounded-md z-[59] shadow-lg border w-48"
                ref={modalRef}
              >
                <div className="px-4 py-2 border-b">
                  <span className="flex items-center gap-2 text-sm font-medium">
                    <UserIcon2 className="w-4 h-4" />
                    {user?.first_name
                      ? `${user.first_name.slice(0, 1).toUpperCase()}${user.first_name.slice(1)} ${user.last_name?.slice(0, 1).toUpperCase()}${user.last_name?.slice(1)}`
                      : 'Profile'}
                  </span>
                </div>
                <Button
                  onClickHandler={() => {
                    dispatch(clearToken());
                  }}
                  className="px-4 py-2 text-left text-sm text-red-500 hover:bg-red-50 transition-colors duration-200"
                >
                  <span className="flex items-center">
                    <LogoutIcon className="w-4 h-4 mr-2" /> Logout
                  </span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
