import './styles/index.css';

import CheckIcon from 'components/Icon/assets/CheckIcon';
import { LoaderStatusEnum } from 'interfaces/common.interface';
import { useSelector } from 'react-redux';
import { getLoaderStatus, getPrimaryStep } from 'reduxStore/slices/loaderSlice';
import Loader from './components/Loader';
import Validation from './components/Validation';

const steps = [
  'Pulling Data',
  'Preparing Model',
  'Solving Scheduling Program',
  'Preparing Output Data',
];

export default function DataValidation() {
  const currentStep = useSelector(getPrimaryStep);
  const loaderStatus = useSelector(getLoaderStatus);

  return (
    <div className="h-max flex items-center justify-center p-4">
      <div className="w-full max-w-4xl">
        <div className="relative">
          <div className="">
            <ul className="grid grid-cols-4 gap-7 relative before:absolute before:content-[''] before:w-[calc(100%_-_200px)] before:mx-auto before:left-0 before:right-0 before:h-[2px] before:bg-primary/10 before:top-3.5 before:-z-1 z-10">
              {steps.map((step, index) => (
                <li
                  key={index}
                  className={`text-center flex items-center flex-col gap-y-2.5 relative ${index !== steps.length - 1 ? "before:absolute before:content-[''] before:h-0.5 before:w-full  before:right-[-57%] before:top-3.5 last:before:opacity-0  before:bg-primary" : ''}`}
                >
                  <span
                    className={`flex items-center justify-center text-white w-7 h-7 rounded-full 
                      ${index < currentStep ? 'bg-primary' : ''}
                      ${index === currentStep ? 'border-[10px] border-solid border-primary bg-primaryLight-20' : ''}
                      ${index > currentStep ? 'border-[10px] border-siteBG bg-primary' : ''} `}
                  >
                    {index < currentStep && <CheckIcon />}
                  </span>
                  <span className="text-base leading-normal font-medium text-grayText">
                    {step}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex-grow overflow-auto">
          <div className="w-full max-w-4xl mx-auto px-4 pb-8">
            {loaderStatus === LoaderStatusEnum.PENDING ||
            loaderStatus === LoaderStatusEnum.COMPLETE ||
            loaderStatus === LoaderStatusEnum.AVAILABILITY_SUCCESS ? (
              <Validation />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
