import Button from 'components/Button/Button';
import DownArrow from 'components/Icon/assets/downArrow';
import EyeIcon from 'components/Icon/assets/EyeIcon';
import Modal from 'components/Modal/Modal';
import { useState } from 'react';
import { IDataError, IDataErrorItem } from '../types';

export default function CollapsableMenu({
  section,
  toggleSection,
  openSections,
  type = 'primary',
}: {
  readonly section: IDataError;
  readonly toggleSection: (id: string) => void;
  readonly openSections: Record<string, boolean>;
  readonly type?: 'primary' | 'secondary';
}) {
  const [resource, setResource] = useState<IDataErrorItem | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className="border rounded-lg bg-white">
      {isOpen && (
        <Modal title={section.label} closeModal={() => setIsOpen(false)}>
          <div className="flex flex-col">
            {resource?.teachworks_id && (
              <div className="flex gap-2">
                <span className="font-bold">Teachworks Id:</span>
                <span>{resource?.teachworks_id}</span>
              </div>
            )}
            {resource?.session_teachworks_id && (
              <div className="flex gap-2">
                <span className="font-bold">Session Teachworks Id:</span>
                <span>{resource?.session_teachworks_id}</span>
              </div>
            )}
            {resource?.first_name && (
              <>
                <div className="flex gap-2">
                  <span className="font-bold">First Name:</span>
                  <span>{resource?.first_name}</span>
                </div>
                <div className="flex gap-2">
                  <span className="font-bold">Last Name:</span>
                  <span>{resource?.last_name}</span>
                </div>
              </>
            )}
            {resource?.name && (
              <div className="flex gap-2">
                <span className="font-bold">Name:</span>
                <span>{resource?.name}</span>
              </div>
            )}
            {resource?.session_name && (
              <div className="flex gap-2">
                <span className="font-bold">Session Name:</span>
                <span>{resource?.session_name}</span>
              </div>
            )}
            {resource?.course_name && (
              <div className="flex gap-2">
                <span className="font-bold">Course Name:</span>
                <span>{resource?.course_name}</span>
              </div>
            )}
            {resource?.teacher && (
              <div className="flex gap-2">
                <span className="font-bold">Teacher:</span>
                <span>{resource?.teacher}</span>
              </div>
            )}
            {resource?.student && (
              <div className="flex gap-2">
                <span className="font-bold">Student:</span>
                <span>{resource?.student}</span>
              </div>
            )}
            {resource?.location && (
              <div className="flex gap-2">
                <span className="font-bold">Location:</span>
                <span>{resource?.location}</span>
              </div>
            )}
            {resource?.from_date && (
              <div className="flex gap-2">
                <span className="font-bold">Date:</span>
                <span>{resource?.from_date}</span>
              </div>
            )}
            {resource?.student_teachworks_id && (
              <div className="flex gap-2">
                <span className="font-bold">Student Id:</span>
                <span>{resource?.student_teachworks_id}</span>
              </div>
            )}
            {resource?.student_name && (
              <div className="flex gap-2">
                <span className="font-bold">Student Name:</span>
                <span>{resource?.student_name}</span>
              </div>
            )}
            {resource?.total_number_of_sessions_student_is_available && (
              <div className="flex gap-2">
                <span className="font-bold">
                  The total number of sessions a student is available for:
                </span>
                <span>
                  {resource?.total_number_of_sessions_student_is_available}
                </span>
              </div>
            )}
            {resource?.student_required_number_of_sessions_in_week && (
              <div className="flex gap-2">
                <span className="font-bold">
                  The number of sessions a student needs per week:
                </span>
                <span>{resource?.student_required_number_of_sessions_in_week}</span>
              </div>
            )}
            {resource?.from_time && (
              <div className="flex gap-2">
                <span className="font-bold">Time:</span>
                <span>
                  {resource?.from_time} - {resource?.to_time}
                </span>
              </div>
            )}
          </div>
        </Modal>
      )}
      <button
        onClick={() => section.items?.length > 0 && toggleSection(section.id)}
        className="w-full px-4 py-3 flex items-center justify-between hover:bg-gray-50 focus:outline-none"
        aria-expanded={openSections[section.id]}
        aria-controls={`content-${section.id}`}
      >
        <div className="flex items-center gap-2 text-left">
          <span
            className={`${section.items?.length === 0 ? 'text-green-600' : type === 'primary' ? 'text-red-500' : 'text-primary'} text-sm`}
          >
            {section.label}
            {'  '}
            {section.items?.length !== 0 && section.items?.length}
          </span>
        </div>
        {section.items?.length !== 0 && (
          <DownArrow
            className={`w-5 h-5 transition-transform duration-300 ${
              openSections[section.id] ? 'transform rotate-180' : ''
            }`}
          />
        )}
      </button>
      <div
        id={`content-${section.id}`}
        className={`transition-all duration-300 ease-in-out ${
          openSections[section.id] ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        } overflow-y-auto`}
      >
        <div className="border-t">
          {section.items?.map((item, index) => (
            <div
              key={index}
              className="px-4 py-2 flex items-center justify-between hover:bg-gray-50"
            >
              <span className="text-sm">
                {item.name ??
                  item.course_name ??
                  item.session_name ??
                  item.student_name ??
                  `${item.first_name} ${item.last_name}`}
              </span>
              <Button
                onClickHandler={() => {
                  setResource(item);
                  setIsOpen(true);
                }}
              >
                <EyeIcon />
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
