import { IUser } from 'modules/Calendar/types';

export interface IEvent {
  id?: number;
  // session?: ISession;
  session_id?: number;
  text?: string;
  start_date: string | Date;
  end_date: string | Date;
  section_id?: number;
  teacher_id?: number;
  classroom_id?: number;
  course_id?: number;
  service_id?: number;
  teacher?: string;
  student?: string;
  classroom?: string;
  color?: string;
  otherColor?: string;
  studentColor?: string;
  teacherColor?: string;
  classroomColor?: string;
  course?: string;
  sessionNumber?: number;
  totalSessions?: number;
  title?: string;
  start_time?: string;
  end_time?: string;
  event_type?: string;
  student_ids?: number[];
  students?: IUser[];
  time_id?: number;
  service?: string;
  availability_id?: string;
  optimizer_id?: string;
  isAccepted?: boolean;
}

export interface IFormValues {
  id?: number;
  session_id?: number;
  title: string;
  students: number[];
  teacher: number | string;
  service: number | string;
  classroom: number | string;
  course: number | string;
  start_date: string;
  start_time: string;
  end_time: string;
  availability_id?: string;
  optimizer_id?: string;
  isAccepted?: boolean;
  // session?: ISession;
}

export enum FilterEnum {
  STUDENT = 'student',
  TEACHER = 'teacher',
  CLASSROOM = 'classroom',
  TIME = 'time',
}

export enum ViewEnum {
  DAY = 'day_timeline',
  WEEK = 'week_timeline',
  FIVE_DAY = 'five_day_timeline',
  MONTH = 'month',
  AGENDA = 'agenda',
}

export const events = {
  SERVICE: {
    id: 1,
    name: 'service_sync_status',
    message: 'Syncing services...',
  },
  SUBJECT: {
    id: 2,
    name: 'subject_sync_status',
    message: 'Syncing subjects...',
  },
  TEACHER: {
    id: 3,
    name: 'teacher_sync_status',
    message: 'Syncing teachers...',
  },
  STUDENT: {
    id: 4,
    name: 'student_sync_status',
    message: 'Syncing students...',
  },
  CLASSROOM: {
    id: 5,
    name: 'classroom_sync_status',
    message: 'Syncing classrooms...',
  },
  SESSION: {
    id: 6,
    name: 'session_sync_status',
    message: 'Syncing sessions...',
  },
  HOLIDAY: {
    id: 7,
    name: 'holiday_sync_status',
    message: 'Syncing holidays...',
  },
  SYNC_ALL: 'sync_all_status',
  CUSTOM_FIELDS: 'custom_field_linking_status',
  COMPLETE_SCHEDULE_GENERATION: 'complete_process_of_schedule_generation',
  SCHEDULE_GENERATION_SYNC: {
    id: 0,
    name: 'process_of_sync_while_schedule_generation',
    message: 'Syncing data from teachworks...',
  },
  CIPLEX_CALL: {
    id: 2,
    name: 'ciplex_call',
    message:
      'Ciplex process completed. Generated schedule is being prepared for the output.',
  },
  ERROR_SCHEDULE_GENERATION: 'error_process_of_schedule_generation',
  SEND_DATA_BACK_TO_TEACHWORK: 'send_data_back_to_teach_works',
  EXISTING_SESSION_IS_TRANSFER: 'existing_session_is_transfer',
  SESSION_TRANSFER: 'existing_session_is_transfer',
  GENERATE_SESSION_BY_AVAILABILITY: 'generate_session_based_on_availability',
  // COMPLETE_PROCESS_OF_SCHEDULE_GENERATION: 'complete_process_of_schedule_generation',
};

export enum StatusEnum {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

export enum LoaderStatusEnum {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  CREATE = 'CREATE',
  COMPLETE = 'COMPLETE',
  CANCEL = 'CANCEL',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR', // data change and problem in code
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE', // ciplex error or database access issue
  INFEASIBLE = 'INFEASIBLE',
  NOT_STARTED = 'NOT_STARTED',
  FINISH = 'FINISH',
  AVAILABILITY_PENDING = 'AVAILABILITY_PENDING',
  AVAILABILITY_SUCCESS = 'AVAILABILITY_SUCCESS',
}
