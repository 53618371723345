import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'reduxStore/store';

type calendarView = {
  calendarView: boolean | null;
  sourceView: boolean | null;
};

const initialState: calendarView = {
  calendarView: null,
  sourceView: null,
};

export const slice = createSlice({
  name: 'calendarView',
  initialState,
  reducers: {
    setCalenderView(state: calendarView, action: PayloadAction<boolean>) {
      state.calendarView = action.payload;
    },
    setSourceView(state: calendarView, action: PayloadAction<boolean>) {
      state.sourceView = action.payload;
    },
  },
});

export const calendarViewSelector = (state: RootStateType) => {
  return state.calendarView.calendarView;
};

export const sourceViewSelector = (state: RootStateType) => {
  return state.calendarView.sourceView;
};

export const { setCalenderView, setSourceView } = slice.actions;

export const { reducer } = slice;
