// ** Packages **
import { combineReducers } from '@reduxjs/toolkit';

// ** Redux Slices **
import { reducer as authReducer } from './slices/authSlice';

import { reducer as toastReducer } from './slices/toastSlice';

import { reducer as tokenReducer } from './slices/tokenSlice';

import { reducer as paginationReducer } from './slices/paginationSlice';

import { reducer as instituteReducer } from './slices/instituteSlice';

import { reducer as loaderSliceReducer } from './slices/loaderSlice';

import { reducer as socketReducer } from './slices/socketSlice';

import { reducer as resourceReducer } from './slices/resourceSlice';

import { reducer as calendarViewReducer } from './slices/calenderViewSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  commonToast: toastReducer,
  token: tokenReducer,
  currentPage: paginationReducer,
  institutes: instituteReducer,
  loaderSlice: loaderSliceReducer,
  socket: socketReducer,
  resources: resourceReducer,
  calendarView: calendarViewReducer,
});

export default rootReducer;
